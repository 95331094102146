<script lang="ts">
  import { page } from "$app/state";
  import { type SanityImageAsset } from "$generated/sanity";
  import { imgProps } from "$src/lib/sanity";
  import { parallax } from "@hyperfocal/actions";
  import ColorPicker from "@hyperfocal/design/components/ColorPicker/ColorPicker.svelte";
  import Field from "@hyperfocal/design/components/FormField/FormField.svelte";
  import RadioGroup from "@hyperfocal/design/components/RadioGroup/RadioGroup.svelte";
  import Select from "@hyperfocal/design/components/Select/Select.svelte";
  import { media } from "@hyperfocal/design/styles/media";
  import { chunk, random } from "lodash-es";
  import columnsIcon from "lucide-static/icons/columns-2.svg?raw";
  import stackedIcon from "lucide-static/icons/gallery-vertical.svg?raw";
  import gridIcon from "lucide-static/icons/layout-grid.svg?raw";
  import AppearanceIcon from "../lib/AppearanceIcon.svelte";

  const PHOTOS_PER_COLUMN = 4;
  const PHOTO_COLUMNS = 2;

  const APPEARANCES = [
    {
      label: "Auto (system)",
      value: "auto",
      icon: AppearanceIcon,
    },
    {
      label: "Light",
      value: "light",
      icon: AppearanceIcon,
    },
    {
      label: "Dark",
      value: "dark",
      icon: AppearanceIcon,
    },
  ];

  const photos = $derived(
    page.data.global.photos.slice(0, PHOTOS_PER_COLUMN * PHOTO_COLUMNS),
  );
  const photoGroups = $derived(chunk(photos, photos.length / PHOTO_COLUMNS));
</script>

<style>
  .graphic {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    @media not all and (--lg) {
      margin: 0 calc(0px - var(--grid-page-gutter));
      width: calc(100% + var(--grid-page-gutter) * 2);
    }
  }

  .background {
    position: absolute;
    display: flex;
    gap: var(--size-2);
    left: 0;
    top: 0;
    width: 100%;
  }

  .photos-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--size-2);
  }

  .photo {
    position: relative;
    width: 100%;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: white;
      opacity: 0.7;
      z-index: var(--layer-1);
    }
  }

  .photo-image {
    width: 100%;
  }

  .foreground {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
    height: 100%;
    z-index: var(--layer-1);
    @media not all and (--lg) {
      padding: 0 var(--grid-page-gutter);
    }
  }

  .card {
    --color-text: white;
    --color-text-secondary: var(--color-grey-400);
    --color-text-tertiary: var(--color-grey-500);
    --color-bg: var(--color-black);
    --color-bg-secondary: var(--color-grey-700);
    --color-bg-tertiary: var(--color-grey-900);
    --color-border: var(--color-grey-700);
    --color-border-secondary: var(--color-grey-700);
    --color-border-tertiary: var(--color-grey-800);
    --color-black-opacity: rgba(16, 24, 28, 0.7);
    --color-black-opacity-900: var(--color-black-opacity);
    --color-grey-opacity: rgba(85, 93, 110, 0.1);
    background: var(--color-black);
    color: white;
    display: flex;
    flex-direction: column;
    gap: var(--size-6);
    width: 100%;
    max-width: var(--size-96);
    padding: var(--size-6);
    border-radius: var(--radius-lg);
    @media not all and (--2xl) {
      transform: none !important;
    }
    @media (--2xl) {
      flex: 1;
      min-width: var(--size-72);
      max-width: 40%;
    }
  }

  .color {
    @media not all and (--2xl) {
      display: none;
    }
  }

  .color-label {
    color: var(--color-text-secondary);
  }
</style>

<div class="graphic">
  <div class="background">
    {#each photoGroups as photos, i}
      <div
        class="photos-list"
        use:parallax={{
          props: {
            [`--photo-list-${i}`]: {
              from: `${random(-30, 0)}%`,
              to: `0%`,
            },
          },
        }}
        style:transform="translateY(var(--photo-list-{i}))"
      >
        {#each photos as photo}
          <div class="photo">
            <img
              class="photo-image"
              sizes="10vw"
              {...imgProps(photo.asset as unknown as SanityImageAsset)}
            />
          </div>
        {/each}
      </div>
    {/each}
  </div>

  <div class="foreground">
    <div
      class="card color"
      use:parallax={{
        props: {
          [`--card-1`]: {
            from: "20%",
            to: "0",
          },
        },
      }}
      style:transform="translateY(var(--card-1))"
    >
      <span class="color-label --text-ui">Accent color</span>
      <ColorPicker value="#675af8" />
    </div>

    <div
      class="card"
      use:parallax={{
        props: {
          [`--card-2`]: {
            from: "-20%",
            to: "0",
          },
        },
      }}
      style:transform="translateY(var(--card-2))"
    >
      {#if $media?.["xl"]}
        <RadioGroup
          label="Home Page"
          name="home"
          fullwidth
          active="galleries"
          options={[
            {
              label: "All Photos",
              value: "photos",
            },
            {
              label: "All Galleries",
              value: "galleries",
            },
            {
              label: "First Gallery",
              value: "gallery",
            },
          ]}
        />
      {/if}

      <RadioGroup
        label="Gallery Layout"
        name="layout"
        active="grid"
        fullwidth
        options={[
          {
            label: "Grid",
            value: "grid",
            icon: $media?.["2xl"] ? gridIcon : "",
          },
          {
            label: "Column",
            value: "column",
            icon: $media?.["2xl"] ? columnsIcon : "",
          },
          {
            label: "Stacked",
            value: "stacked",
            icon: $media?.["2xl"] ? stackedIcon : "",
          },
        ]}
      />
      {#if $media?.["md"]}
        <RadioGroup
          label="Gallery Size"
          name="size"
          active="large"
          fullwidth
          options={[
            { label: "Small", value: "small" },
            { label: "Medium", value: "medium" },
            { label: "Large", value: "large" },
          ]}
        />
      {/if}

      <Field label="Appearance">
        <Select value={APPEARANCES[0]} items={APPEARANCES} />
      </Field>
    </div>
  </div>
</div>
