<!--
### Global website header
 -->
<script lang="ts">
  import { page } from "$app/state";
  import wordmark from "@hyperfocal/design/assets/wordmark.svg?raw";
  import Button from "@hyperfocal/design/components/Button/Button.svelte";
  import Hamburger from "@hyperfocal/design/components/Hamburger/Hamburger.svelte";
  import type { HTMLAttributes } from "svelte/elements";
  import MobileMenu from "./lib/MobileMenu/MobileMenu.svelte";

  type HeaderProps = {
    /** Primary header navigation */
    nav: { label: string; href: string }[];
  } & HTMLAttributes<HTMLDivElement>;

  const { nav = [] }: HeaderProps = $props();

  let mobMenuOpen = $state(false);
</script>

<style>
  header {
    grid-auto-rows: auto;
    align-items: flex-end;
    z-index: var(--layer-5);
    height: var(--app-header-height);
  }

  .header,
  .nav,
  .actions {
    display: flex;
    align-items: center;
    gap: var(--size-7);
  }

  .header {
    width: 100%;
    justify-content: space-between;
  }

  .actions,
  .nav {
    @media not all and (--lg) {
      display: none;
    }
  }

  .logo {
    z-index: var(--layer-2);
    height: var(--size-6);
    @media (--md) {
      height: var(--size-8);
    }
    & :global(svg) {
      fill: currentColor;
      height: 100%;
    }
  }

  .nav-item {
    font: var(--text-lg);
    font-weight: var(--weight-semibold);
    transition: color 250ms var(--ease-standard);
    &:hover,
    &.active {
      color: var(--color-accent-600);
    }
  }

  .actions {
    --button-font-size: var(--scale-0);
  }

  .mobile-menu {
    @media (--lg) {
      display: none;
    }
    & :global(.hamburger) {
      z-index: var(--layer-2);
    }
  }
</style>

<header class="--grid-page">
  <div class="header">
    <a href="/" class="logo">
      {@html wordmark}
    </a>

    <nav class="nav">
      {#each nav as { label, href }}
        <a
          class="nav-item"
          class:active={page.url.pathname.startsWith(href)}
          {href}>{label}</a
        >
      {/each}
    </nav>

    <div class="actions">
      <a class="nav-item" href="https://app.hyperfocal.photo/login">Login</a>
      <Button theme="primary" href="https://app.hyperfocal.photo/signup"
        >Signup</Button
      >
    </div>

    <div class="mobile-menu">
      <Hamburger
        class="hamburger"
        active={mobMenuOpen}
        onclick={() => (mobMenuOpen = !mobMenuOpen)}
      />
      <MobileMenu {nav} bind:open={mobMenuOpen} />
    </div>
  </div>
</header>
