import type { Action } from "svelte/action";

/**
 * Attaches interaction listeners to a DOM element to dispatch a custom "interact" event.
 * Listens for click or "Enter" keydown events on the element, then dispatches a custom event with the original event in its details.
 *
 * @param node The DOM element to attach the interaction listeners to.
 * @returns void
 */
export const interact: Action<HTMLElement, ((e: Event) => void) | undefined> = (
  node,
  callback,
) => {
  const eventHandler = (e: KeyboardEvent | MouseEvent) => {
    if (e instanceof MouseEvent || e.key === "Enter") {
      callback?.(e);
    }
  };

  if (callback) {
    node.addEventListener("click", eventHandler);
    node.addEventListener("keydown", eventHandler);
    node.setAttribute("tabindex", "0");
  }

  return {
    destroy() {
      node.removeEventListener("click", eventHandler);
      node.removeEventListener("keydown", eventHandler);
    },
  };
};
